import React from "react";
import GeneralBox from "../general/GeneralBox";
import { NumericFormat } from "react-number-format";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { da } from "@faker-js/faker";

const OrdersData = observer(
  ({
    text,
    amount,
    hour,
    count,
    css,
    dailyAverage,
    yesterdayDWHGMV,
    postFix = "",
    isShowFunc = () => {
      return { show: true };
    },
  }) => {
    const [showFunResult, setshowFunResult] = React.useState(isShowFunc());
    const [diffPercent, setDiffPercent] = React.useState(null);
    useEffect(() => {
      if (dailyAverage !== undefined) {
        //calculate difference between daily average and amount in percentage
        let diff = amount - dailyAverage;
        let percent = (diff / dailyAverage) * 100;
        setDiffPercent(percent);
      }
    }, [dailyAverage]);
    return (
      <GeneralBox
        dPadding="2"
        hasBorder={true}
        hasBackground={true}
        css={"order-sales-info mb-2 yesterday-container " + css}
      >
        <div className="d-flex" style={{ height: "100%" }}>
          <div className="dash-yesterday-content">
            <div className="d-flex flex-column" style={{ height: "100%" }}>
              <div className="d-flex dash-side-header">
                <i className="bi bi-coin"></i>
                <div>
                  {text} {hour !== undefined ? `(${hour})` : ""}
                </div>
              </div>
              {!showFunResult.show ? (
                <div className="d-flex flex-wrap order-data-custom-text">
                  {showFunResult.text}
                </div>
              ) : (
                <>
                  <div className="dash-yesterday-amount d-flex flex-wrap">
                    <NumericFormat
                      value={amount}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                      suffix={postFix}
                    />

                    <div
                      data-hidden={!diffPercent}
                      data-positive={diffPercent >= 0}
                      className="d-flex align-items-center yesterday-avg-diff"
                    >
                      <i
                        style={{ marginLeft: "5px" }}
                        className={
                          "bi " +
                          (diffPercent >= 0
                            ? "bi-caret-up-fill"
                            : "bi-caret-down-fill")
                        }
                      >
                        {" "}
                      </i>
                      {diffPercent && diffPercent.toFixed(2) + "%"}
                    </div>
                  </div>
                  <div style={{fontSize:"13px", marginBottom:"5px"}}>
                     <span>DWH GMV:</span>
                     <span className="highlight-amount" style={{marginLeft:"5px"}}>
                     {yesterdayDWHGMV>0?<NumericFormat
                      value={yesterdayDWHGMV}
                      decimalScale={0}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                      suffix={postFix}
                    />:"N/A"}                                      
                     </span>
                  </div>                  
                  <div className="dash-yesterday-count">
                    <NumericFormat
                      value={count}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={""}
                    />{" "}
                    <span className="ms-1"> orders</span>
                  </div>

                </>
              )}
            </div>
          </div>
        </div>
      </GeneralBox>
    );
  }
);

export default OrdersData;
